
.container-f {
    width: '100% !important';
    margin: 0 auto;
    }
    
.wrapper {
display: flex;
flex-direction: row;
flex-wrap: wrap;
width: 100%;
vertical-align: middle;

}

/* Breadcrups CSS */

.arrow-steps .step {
font-size: 14px;
text-align: center;
cursor: default;
margin: 0 3px;
padding: 10px 10px 10px 30px;
min-width: 10px;
width: calc(95%/6);
float: left;
position: relative;
background-color: #d9e3f7;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none; 
transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
content: " ";
position: absolute;
top: 0px;
right: -39px;
width: 0;
height: 0;
border-top: 40px solid transparent;
border-bottom: 40px solid transparent;
border-left: 40px solid #d9e3f7;	
z-index: 2;
transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
right: auto;
left: 0;
border-left: 40px solid #fff;	
z-index: 0;
}

.arrow-steps .step:first-child:before {
border: none;
}

.arrow-steps .step:first-child {
border-top-left-radius: 4px;
border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
position: relative;
}

.arrow-steps .step span:before {
opacity: 0;
content: "✔";
position: absolute;
top: 0px;
left: -40px;
}

.arrow-steps .step.done span:before {
opacity: 1;
-webkit-transition: opacity 0.3s ease 0.5s;
-moz-transition: opacity 0.3s ease 0.5s;
-ms-transition: opacity 0.3s ease 0.5s;
transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
color: #fff;
background-color: #23468c;
}
.arrow-steps .step.current h2 {
    color: #fff;

}

.arrow-steps .step.current:after {
border-left: 40px solid #23468c;	
}