//
// Card
//

.custom-scroll {
}
.custom-scroll::-webkit-scrollbar {
  height: 5px;
}

.custom-scroll::-webkit-scrollbar-track {
  background: white;
}

.custom-scroll::-webkit-scrollbar-thumb {
  width: 10px;
  background: rgb(195, 193, 193);
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.table-responsive::-webkit-scrollbar {
  height: 5px;
}

.table-responsive::-webkit-scrollbar-track {
  background: white;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: rgb(195, 193, 193);
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
  @if $enable-shadows {
    border: 0;
  }
}

.card-translucent {
  background-color: rgba(18, 91, 152, 0.08);
}

.card-img-top {
  @if ($enable-shadows == false) {
    width: calc(100% + 2px);
    margin-left: -1px;
    margin-top: -1px;
  }
}

.card-img-bottom {
  @if ($enable-shadows == false) {
    width: calc(100% + 2px);
    margin-left: -1px;
    margin-bottom: -1px;
  }
}

// Card decks

.card-deck {
  .card {
    margin-bottom: $grid-gutter-width;
  }
}

// Card with shdadow

.card {
  &.shadow {
    border: 0 !important;
  }
}

//
// Columns
//

.card-columns {
  @include media-breakpoint-up(sm) {
    column-count: 1;
  }

  @include media-breakpoint-up(md) {
    column-count: 2;
  }

  @include media-breakpoint-up(xl) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;
  }
}


/* Global CSS, you probably don't need that */

.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}

