.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body {
  height: 100%;
  /* overflow: hidden; */
}

.row {
  flex-direction: row;
  display: flex;
}

.column {
  height: 100%;
  float: left;
}

.c10 {
	width: 10%;
}

.c20 {
	width: 20%;
}

.c80 {
	width: 80%;
}

.c90 {
	width: 90%;
}

.left-menu {
  border-right: 2px solid black;
}

.end {
  justify-content: flex-end;
  margin-right: 20px;
}

.general {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

header {
  border-bottom: 2px solid black;
}

/* tree */

.tree {
  transform: rotate(0deg);
  transform-origin: 50%;
}
.tree ul {
  position: relative;
  padding: 1em 0;
  white-space: nowrap;
  margin: 0 auto;
  text-align: center;
}
.tree ul::after {
  content: '';
  display: table;
  clear: both;
}
.tree li {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 1em 0.5em 0 0.5em;
}
.tree li::before, .tree li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 1em;
}
.tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}
.tree li:only-child::after, .tree li:only-child::before {
  display: none;
}
.tree li:only-child {
  padding-top: 0;
}
.tree li:first-child::before, .tree li:last-child::after {
  border: 0 none;
}
.tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
}
.tree li:first-child::after {
  border-radius: 5px 0 0 0;
}
.tree ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 1em;
}
.tree li a {
  border: 1px solid #ccc;
  padding: 0.5em 0.75em;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  color: #333;
  position: relative;
  top: 1px;
  transform: rotate(0deg);
}
.tree li a:hover, .tree li a:hover + ul li a {
  background: #1c0b68;
  color: #fff;
  border: 1px solid #1c0b68;
}
.tree li a:hover + ul li::after, .tree li a:hover + ul li::before, .tree li a:hover + ul::before, .tree li a:hover + ul ul::before {
  border-color: #1c0b68;
}

/* tree */


.tabs {
  display: flex;
  flex-direction: row;
}

.tabs div {
  flex: 1;
  text-align: center;
  padding: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.tab-selected {
  background-color: #ccc;
}

.form {
  z-index: 100;
  background-color: white;
  border: 1px solid black;
  padding: 10px;
}

.simple_table td, th {
  border: 1px solid black;
}

.simple_table {
  margin: 10px;
  width: 100%;
  border-collapse: collapse;
}

.login-div {
  margin: 20px;
}

/* tree-left */

.tree-left {
  transform: rotate(0deg);
  /* transform: rotate(-90deg); */
  transform-origin: 50%;
}
.tree-left ul {
  position: relative;
  padding: 1em 0;
  white-space: nowrap;
  margin: 0 auto;
  text-align: center;
}
.tree-left ul::after {
  content: '';
  display: table;
  clear: both;
}
.tree-left li {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 1em 0.5em 0 0.5em;
}
.tree-left li::before, .tree-left li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 1em;
}
.tree-left li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}
.tree-left li:only-child::after, .tree-left li:only-child::before {
  display: none;
}
.tree-left li:only-child {
  padding-top: 0;
}
.tree-left li:first-child::before, .tree-left li:last-child::after {
  border: 0 none;
}
.tree-left li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
}
.tree-left li:first-child::after {
  border-radius: 5px 0 0 0;
}
.tree-left ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 1em;
}
.tree-left li a {
  border: 1px solid #ccc;
  padding: 0.5em 0.75em;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  color: #333;
  position: relative;
  top: 1px;
  transform: rotate(0deg);
  /* transform: rotate(90deg); */
}
.tree-left li a:hover, .tree-left li a:hover + ul li a {
  background: #1c0b68;
  color: #fff;
  border: 1px solid #1c0b68;
}
.tree-left li a:hover + ul li::after, .tree-left li a:hover + ul li::before, .tree-left li a:hover + ul::before, .tree-left li a:hover + ul ul::before {
  border-color: #1c0b68;
}

/* tree */




.sidebar-container {
  position: fixed;
  z-index: 9999;
  top: 30%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left: 2px solid #ccc;
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
}

.sidebar-toggle {
  flex-shrink: 0;

  background: #fff;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.sidebar-toggle.open {
  margin-right: 0;
}

.sidebar-icon {
  font-size: 36px;
}

.sidebar {
  width: 600px;
  background-color: #fff;
  padding: 10px;
}

