


.dropzoneContainer {
  border: 2px dashed gray;
  border-radius: 10px; /* Curved borders */
  padding: 20px;
  text-align: center; /* Center the text */
  transition: border .24s ease-in-out;
  cursor: pointer; /* Change cursor on hover */
}

/* Optional: Change border color on drag over */
.dropzoneContainer:hover {
  border-color: #2196f3; /* Change to desired hover color */
}



.file-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin-top: 10px;
  background-color: #f2f2f2;
  border-radius: 4px;
}

.file-details span {
  flex-grow: 1;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-details button {
  background-color: #424242;
  border: none;
  border-radius: 20%;
  color: white;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-details button:hover {
  background-color: #616161;
}




.document-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-color: #ffffff;
  border-bottom: #e9ecef;
  border-style: solid;
}


.document-header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: bold;
  background-color: #f6f9fc;
  border-color: #f6f9fc;
  border-bottom: #e9ecef;
  border-top: #e9ecef;

  border-style: solid;
  color: #8898aa;
}

.document-attribute, .document-actions {
  flex: 1;
  text-align: left;
  display: flex;

  justify-content: flex-start;

  padding: 5px;
  margin-left: 10px;
  margin-bottom: 0px;
}

.document-actions > * {
  margin-right: 20px;
}

.toggle-button-container {
  display: flex;
  justify-content: flex-start; /* Align button to the left */
}

.toggle-button {
  background-color: rgba(255, 255, 255, 0);
  border: none;
  cursor: pointer;
  margin-left: -10px; /* Adjust as needed */
}

.versioned-document {
  margin-left: 20px; /* Indent versioned documents */
  border-left: 3px solid #81b1e6; /* Blue left border for versions */
}


.document-row:hover {
  background-color: #f2f2f2; /* Lighter green background on hover */
}
