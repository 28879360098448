body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* css style over div that align elements inside to the right end */
.alignRightDiv {
  float: right;
  margin-right: 10px;
}

.stages-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  width: 80%;
  margin-bottom: 10px;
}

.stages-element {
  border: 1px solid black;
  /* display: flex; */
  flex: 1 0 32%;
  margin: 5px;
  padding: 5px;
  padding-right: 10px;
  flex-direction: column;
  align-items: center;
}

.cards-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  width: 80%;
  margin-bottom: 10px;
}

.cards-element {
  border: 1px solid black;
  display: flex;
  flex: 1 0 32%;
  margin: 5px;
  padding: 5px;
  padding-right: 10px;
  flex-direction: column;
  align-items: center;
}

.react-datepicker-wrapper {
 display: block !important;
}